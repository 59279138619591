const buildPropertyInfoObject = (queryData, queryData2, uData) => {
  const dataObject = {
    qdbapi: {
      udata: uData,
      apptoken: "d8phrhgbq4mmfwc3rkhnddciskd3",
      QueryString: queryData,
      QueryString2: queryData2,
      fmt: "structured"
    }
  };
  if (queryData && queryData2) {
    dataObject.qdbapi.query = `{'44'.EX.'${queryData}'}AND{'116'.EX.'${queryData2}'}`;
  }
  return dataObject;
};

export default buildPropertyInfoObject;
